import PUTSMBM from './PUTSMBM';

const smbmRatings = [
  -99, -99, -99, -99, -99, -99, -99, -99, -99, -99, -99, -99, -99, -99,
];
const questionnaire = 'SMBM';

const SMBMRatings = (question, rating) => {
  if (rating === question + 'never-or-almost-never') {
    rating = 1;
  }
  if (rating === question + 'very-infrequently') {
    rating = 2;
  }
  if (rating === question + 'quite-infrequently') {
    rating = 3;
  }
  if (rating === question + 'sometimes') {
    rating = 4;
  }
  if (rating === question + 'quite-frequently') {
    rating = 5;
  }
  if (rating === question + 'very-frequently') {
    rating = 6;
  }
  if (rating === question + 'always-or-almost-always') {
    rating = 7;
  }

  if (question === 'SMBM1') {
    smbmRatings[0] = rating;
  }

  if (question === 'SMBM2') {
    smbmRatings[1] = rating;
  }

  if (question === 'SMBM3') {
    smbmRatings[2] = rating;
  }

  if (question === 'SMBM4') {
    smbmRatings[3] = rating;
  }

  if (question === 'SMBM5') {
    smbmRatings[4] = rating;
  }

  if (question === 'SMBM6') {
    smbmRatings[5] = rating;
  }

  if (question === 'SMBM7') {
    smbmRatings[6] = rating;
  }
  if (question === 'SMBM8') {
    smbmRatings[7] = rating;
  }

  if (question === 'SMBM9') {
    smbmRatings[8] = rating;
  }

  if (question === 'SMBM10') {
    smbmRatings[9] = rating;
  }

  if (question === 'SMBM11') {
    smbmRatings[10] = rating;
  }

  if (question === 'SMBM12') {
    smbmRatings[11] = rating;
  }

  if (question === 'SMBM13') {
    smbmRatings[12] = rating;
  }

  if (question === 'SMBM14') {
    smbmRatings[13] = rating;
  }

  console.log(questionnaire, smbmRatings);
  PUTSMBM(smbmRatings);
};

export default SMBMRatings;
