import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

import './page.css';

import ShiftRatings from '../utilities/ShiftRatings';

import ProgressBar from '../components/Progress';
import '../components/progress.css';

import PUTEndTime from '../utilities/PUTEndTime';

import './peakEndRule.css';

const recencyResponses = [
  { id: '<24 hours', title: '<24 hours' },
  { id: '1-6 days', title: '1-6 days' },
  { id: '1 week', title: '1 week' },
  { id: '2 weeks', title: '2 weeks' },
  { id: '>2 weeks', title: '>2 weeks' },
];

const stressLevelResponses = [
  { id: '1', title: '1 (Least Stress)' },
  { id: '2', title: '2' },
  { id: '3', title: '3' },
  { id: '4', title: '4' },
  { id: '5', title: '5' },
  { id: '6', title: '6' },
  { id: '7', title: '7' },
  { id: '8', title: '8' },
  { id: '9', title: '9' },
  { id: '10', title: '10 (Unbearable Stress)' },
];

const timeResponses = [
  { id: 'day', title: 'day' },
  { id: 'night', title: 'night' },
];

const durationResponses = [
  { id: '<8 hours', title: '<8 hours' },
  { id: '8 hours', title: '8 hours' },
  { id: '10 hours', title: '10 hours' },
  { id: '12 hours', title: '12 hours' },
  { id: '>12 hours', title: '>12hours' },
];

const weekDayResponses = [
  { id: 'weekday', title: 'weekday' },
  { id: 'weekend', title: 'weekend' },
];

const PeakEndRule = () => {
  const [progressValue, setProgressValue] = useState(80);

  const [shiftRecency, setshiftRecency] = useState(false);
  const [shiftStress, setShiftStress] = useState(false);
  const [shiftTime, setShiftTime] = useState(false);
  const [shiftDuration, setShiftDuration] = useState(false);
  const [shiftDay, setShiftDay] = useState(false);

  const [submitSkip, setSubmitSkip] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();

  const ratingHandler = (event) => {
    // console.dir(event.target);
    const rating = event.target.id;
    const question = event.target.name;

    if (question === 'shiftRecency') {
      setshiftRecency(question);
    }
    if (question === 'shiftStress') {
      setShiftStress(question);
    }
    if (question === 'shiftTime') {
      setShiftTime(question);
    }
    if (question === 'shiftDuration') {
      setShiftDuration(question);
    }
    if (question === 'shiftDay') {
      setShiftDay(question);
    }
    setProgressValue(progressValue + 2);
    ShiftRatings(question, rating);
  };

  const sessionHandler = () => {
    const skippedItems = [];

    if (shiftRecency === false) {
      skippedItems.push(' 1');
    }
    if (shiftStress === false) {
      skippedItems.push(' 2');
    }
    if (shiftTime === false) {
      skippedItems.push(' 3');
    }
    if (shiftDuration === false) {
      skippedItems.push(' 4');
    }
    if (shiftDay === false) {
      skippedItems.push(' 5');
    }

    if (shiftRecency === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (shiftStress === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (shiftTime === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (shiftDuration === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (shiftDay === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else {
      // store endTime in db
      var xhr = new XMLHttpRequest();
      xhr.open(
        'PUT',
        'https://6jk00r88v6.execute-api.us-east-1.amazonaws.com/dev/endtime'
      );
      xhr.onreadystatechange = function (event) {
        console.log(event.target.response);
      };
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(
        JSON.stringify({
          id: window.currentId,
          endTime: Date.now(),
        })
      );
      history.push('/register');
    }
  };

  return (
    <div>
      <ScrollToTop smooth />
      <div className="page">
        <h1>Experience of Most Recent Work Shift*</h1>
        <p>Instructions:</p>
        <p>
          Based on your most recent shift, please respond to the following
          questions.
        </p>
        <hr />
        <p>1. How much time has passed since your most recent shift?</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {recencyResponses.map((recencyResponse) => (
              <div key={recencyResponse.id} className="flex items-center">
                <input
                  id={recencyResponse.id}
                  name="shiftRecency"
                  type="radio"
                  // defaultChecked={recencyResponse.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={recencyResponse.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {recencyResponse.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          2. On a scale from 1 to 10, with 1 representing the least possible
          stress and 10 representing a stress that is unbearable, how stressful
          was your most recent shift?
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {stressLevelResponses.map((stressLevelResponse) => (
              <div key={stressLevelResponse.id} className="flex items-center">
                <input
                  id={stressLevelResponse.id}
                  name="shiftStress"
                  type="radio"
                  // defaultChecked={stressLevelResponse.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={stressLevelResponse.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {stressLevelResponse.title}
                </label>
              </div>
            ))}
          </div>
          {/* <div className="peakEnd__stressAnchors">
            <p className="peakEnd__leastAnchor">Least Stress</p>
            <p className="peakEnd__unbearableAnchor">Unbearable Stress</p>
          </div> */}
        </fieldset>
        <hr />
        <p>
          3. Was your most recent shift primarily during the day or primarily
          during the night?
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {timeResponses.map((timeResponse) => (
              <div key={timeResponse.id} className="flex items-center">
                <input
                  id={timeResponse.id}
                  name="shiftTime"
                  type="radio"
                  // defaultChecked={timeResponse.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={timeResponse.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {timeResponse.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>4. How long was your most recent shift?</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {durationResponses.map((durationResponse) => (
              <div key={durationResponse.id} className="flex items-center">
                <input
                  id={durationResponse.id}
                  name="shiftDuration"
                  type="radio"
                  // defaultChecked={durationResponse.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={durationResponse.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {durationResponse.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>5. Was your most recent shift on a weekday or on a weekend?</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {weekDayResponses.map((weekDayResponse) => (
              <div key={weekDayResponse.id} className="flex items-center">
                <input
                  id={weekDayResponse.id}
                  name="shiftDay"
                  type="radio"
                  // defaultChecked={weekDayResponse.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={weekDayResponse.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {weekDayResponse.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p className="citation">
          *Harker, H., Parmenter, E. (2022). Peak-End Rule Nurse Questionnaire
        </p>
      </div>
      <div className="buttonDiv">
        <button
          type="button"
          className="button inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => sessionHandler()}
        >
          Submit
        </button>
      </div>
      <p className="page__count">Page 5 of 5</p>
      <div className="progressBar">
        <ProgressBar
          color={'green'}
          width={'300px'}
          value={progressValue}
          max={100}
        />
      </div>
    </div>
  );
};

export default PeakEndRule;
