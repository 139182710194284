import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

import './page.css';
import SMBMRatings from '../utilities/SMBMRatings';
import ProgressBar from '../components/Progress';
import '../components/progress.css';

const SMBMResponses = [
  { id: 'never-or-almost-never', title: 'Never or almost never' },
  {
    id: 'very-infrequently',
    title: 'Very infrequently',
  },
  {
    id: 'quite-infrequently',
    title: 'Quite infrequently',
  },
  {
    id: 'sometimes',
    title: 'Sometimes',
  },
  {
    id: 'quite-frequently',
    title: 'Quite frequently',
  },
  {
    id: 'very-frequently',
    title: 'Very frequently',
  },
  {
    id: 'always-or-almost-always',
    title: 'Always or almost always',
  },
];

const SMBM = () => {
  const [progressValue, setProgressValue] = useState(35);

  const [SMBM1, setSMBM1] = useState(false);
  const [SMBM2, setSMBM2] = useState(false);
  const [SMBM3, setSMBM3] = useState(false);
  const [SMBM4, setSMBM4] = useState(false);
  const [SMBM5, setSMBM5] = useState(false);
  const [SMBM6, setSMBM6] = useState(false);
  const [SMBM7, setSMBM7] = useState(false);
  const [SMBM8, setSMBM8] = useState(false);
  const [SMBM9, setSMBM9] = useState(false);
  const [SMBM10, setSMBM10] = useState(false);
  const [SMBM11, setSMBM11] = useState(false);
  const [SMBM12, setSMBM12] = useState(false);
  const [SMBM13, setSMBM13] = useState(false);
  const [SMBM14, setSMBM14] = useState(false);

  const [submitSkip, setSubmitSkip] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();

  const ratingHandler = (event) => {
    // console.dir(event.target);
    const rating = event.target.id;
    const question = event.target.name;
    if (question === 'SMBM1') {
      setSMBM1(question);
    }
    if (question === 'SMBM2') {
      setSMBM2(question);
    }
    if (question === 'SMBM3') {
      setSMBM3(question);
    }
    if (question === 'SMBM4') {
      setSMBM4(question);
    }
    if (question === 'SMBM5') {
      setSMBM5(question);
    }
    if (question === 'SMBM6') {
      setSMBM6(question);
    }
    if (question === 'SMBM7') {
      setSMBM7(question);
    }
    if (question === 'SMBM8') {
      setSMBM8(question);
    }
    if (question === 'SMBM9') {
      setSMBM9(question);
    }
    if (question === 'SMBM10') {
      setSMBM10(question);
    }
    if (question === 'SMBM11') {
      setSMBM11(question);
    }
    if (question === 'SMBM12') {
      setSMBM12(question);
    }
    if (question === 'SMBM13') {
      setSMBM13(question);
    }
    if (question === 'SMBM14') {
      setSMBM14(question);
    }

    setProgressValue(progressValue + 2);
    SMBMRatings(question, rating);
  };

  const sessionHandler = () => {
    const skippedItems = [];

    if (SMBM1 === false) {
      skippedItems.push(' 1');
    }
    if (SMBM2 === false) {
      skippedItems.push(' 2');
    }
    if (SMBM3 === false) {
      skippedItems.push(' 3');
    }
    if (SMBM4 === false) {
      skippedItems.push(' 4');
    }
    if (SMBM5 === false) {
      skippedItems.push(' 5');
    }
    if (SMBM6 === false) {
      skippedItems.push(' 6');
    }
    if (SMBM7 === false) {
      skippedItems.push(' 7');
    }
    if (SMBM8 === false) {
      skippedItems.push(' 8');
    }
    if (SMBM9 === false) {
      skippedItems.push(' 9');
    }
    if (SMBM10 === false) {
      skippedItems.push(' 10');
    }
    if (SMBM11 === false) {
      skippedItems.push(' 11');
    }
    if (SMBM12 === false) {
      skippedItems.push(' 12');
    }
    if (SMBM13 === false) {
      skippedItems.push(' 13');
    }
    if (SMBM14 === false) {
      skippedItems.push(' 14');
    }

    if (SMBM1 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM2 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM3 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM4 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM5 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM6 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM7 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM8 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM9 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM10 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM11 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM12 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM13 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else if (SMBM14 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Click 'OK' to complete the item(s) you skipped and click 'Submit' to continue; or to continue without completing the skipped item(s), click 'OK' and then click 'submit'.`
      );
    } else {
      history.push('/satisfactionsurvey');
    }
  };

  return (
    <div>
      <ScrollToTop smooth />
      <div className="page">
        <h1>How Do You Feel at Work?</h1>

        <p>Instructions:</p>
        <p>
          Below are a number of statements that describe different feelings that
          you may feel at work. Please indicate how often, in the past 30
          workdays, you have felt each of the following feelings:
        </p>

        <br></br>
        <hr />
        <p>1. I feel tired</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM1response) => (
              <div
                key={'SMBM1' + SMBM1response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM1' + SMBM1response.id}
                  name="SMBM1"
                  type="radio"
                  // defaultChecked={SMBM1response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM1' + SMBM1response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM1response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>2. I have no energy for going to work in the morning </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM2response) => (
              <div
                key={'SMBM2' + SMBM2response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM2' + SMBM2response.id}
                  name="SMBM2"
                  type="radio"
                  // defaultChecked={SMBM2response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM2' + SMBM2response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM2response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>3. I feel physically drained</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM3response) => (
              <div
                key={'SMBM3' + SMBM3response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM3' + SMBM3response.id}
                  name="SMBM3"
                  type="radio"
                  // defaultChecked={SMBM3response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM3' + SMBM3response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM3response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>4. I feel fed up</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM4response) => (
              <div
                key={'SMBM4' + SMBM4response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM4' + SMBM4response.id}
                  name="SMBM4"
                  type="radio"
                  // defaultChecked={SMBM4response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM4' + SMBM4response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM4response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>5. I feel like my “batteries” are “dead”</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM5response) => (
              <div
                key={'SMBM5' + SMBM5response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM5' + SMBM5response.id}
                  name="SMBM5"
                  type="radio"
                  // defaultChecked={SMBM5response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM5' + SMBM5response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM5response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>6. I feel burned out </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM6response) => (
              <div
                key={'SMBM6' + SMBM6response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM6' + SMBM6response.id}
                  name="SMBM6"
                  type="radio"
                  // defaultChecked={SMBM6response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM6' + SMBM6response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM6response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>7. My thinking process is slow</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM7response) => (
              <div
                key={'SMBM7' + SMBM7response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM7' + SMBM7response.id}
                  name="SMBM7"
                  type="radio"
                  // defaultChecked={SMBM7response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM7' + SMBM7response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM7response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>8. I have difficulty concentrating </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM8response) => (
              <div
                key={'SMBM8' + SMBM8response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM8' + SMBM8response.id}
                  name="SMBM8"
                  type="radio"
                  // defaultChecked={SMBM8response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM8' + SMBM8response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM8response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>9. I feel I'm not thinking clearly</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM9response) => (
              <div
                key={'SMBM9' + SMBM9response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM9' + SMBM9response.id}
                  name="SMBM9"
                  type="radio"
                  // defaultChecked={SMBM9response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM9' + SMBM9response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM9response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>10. I feel I'm not focused in my thinking </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM10response) => (
              <div
                key={'SMBM10' + SMBM10response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM10' + SMBM10response.id}
                  name="SMBM10"
                  type="radio"
                  // defaultChecked={SMBM10response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM10' + SMBM10response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM10response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>11. I have difficulty thinking about complex things </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM11response) => (
              <div
                key={'SMBM11' + SMBM11response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM11' + SMBM11response.id}
                  name="SMBM11"
                  type="radio"
                  // defaultChecked={SMBM11response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM11' + SMBM11response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM11response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          12. I feel I am unable to be sensitive to the needs of coworkers and
          patients{' '}
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM12response) => (
              <div
                key={'SMBM12' + SMBM12response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM12' + SMBM12response.id}
                  name="SMBM12"
                  type="radio"
                  // defaultChecked={SMBM12response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM12' + SMBM12response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM12response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          13. I feel I am not capable of investing emotionally in coworkers and
          patients{' '}
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM13response) => (
              <div
                key={'SMBM13' + SMBM13response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM13' + SMBM13response.id}
                  name="SMBM13"
                  type="radio"
                  // defaultChecked={SMBM13response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM13' + SMBM13response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM13response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          14. I feel I am not capable of being sympathetic to co-workers and
          patients{' '}
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {SMBMResponses.map((SMBM14response) => (
              <div
                key={'SMBM14' + SMBM14response.id}
                className="flex items-center"
              >
                <input
                  id={'SMBM14' + SMBM14response.id}
                  name="SMBM14"
                  type="radio"
                  // defaultChecked={SMBM14response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'SMBM14' + SMBM14response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {SMBM14response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
      </div>
      <div className="buttonDiv">
        <button
          type="button"
          className="button inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => sessionHandler()}
        >
          Submit
        </button>
      </div>
      <p className="page__count">Page 3 of 5</p>
      <div className="progressBar">
        <ProgressBar
          color={'green'}
          width={'300px'}
          value={progressValue}
          max={100}
        />
      </div>
    </div>
  );
};

export default SMBM;
