const PUTPCQ = (id, pcqRatings) => {
  console.log(id, pcqRatings);
  var xhr = new XMLHttpRequest();
  xhr.open(
    'PUT',
    'https://6jk00r88v6.execute-api.us-east-1.amazonaws.com/dev/pcq'
  );
  xhr.onreadystatechange = function (event) {
    console.log(event.target.response);
  };
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(
    JSON.stringify({
      id: id,
      PCQ: pcqRatings,
    })
  );
};

export default PUTPCQ;
