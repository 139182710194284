const POSTRegistrationData = (
  age,
  gender,
  degree,
  city,
  state,
  directPatientCarePercent,
  jobYears,
  professionYears,
  supervisor,
  annualCompensation,
  secondJob,
  organization
) => {
  // console.log(window.currentId);
  // console.log(age);
  // console.log(gender);
  // console.log(degree);
  // console.log(city);
  // console.log(state);
  // console.log(directPatientCarePercent);
  // console.log(jobYears);
  // console.log(professionYears);
  // console.log(supervisor);
  // console.log(annualCompensation);
  // console.log(secondJob);

  var xhr = new XMLHttpRequest();
  xhr.open(
    'PUT',
    'https://6jk00r88v6.execute-api.us-east-1.amazonaws.com/dev/registration'
  );
  xhr.onreadystatechange = function (event) {
    console.log(event.target.response);
  };
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(
    JSON.stringify({
      id: window.currentId,
      age: age,
      gender: gender,
      degree: degree,
      city: city,
      state: state,
      directPatientCarePercent: directPatientCarePercent,
      jobYears: jobYears,
      professionYears: professionYears,
      supervisor: supervisor,
      annualCompensation: annualCompensation,
      secondJob: secondJob,
      organization: organization,
    })
  );
};

export default POSTRegistrationData;
