import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import POSTRegistrationData from '../utilities/POSTRegistrationData';
import ProgressBar from '../components/Progress';
import { CurrentUserName } from '../App';

import './page.css';
import '../components/progress.css';

let organization = '';

const skippedItems = [];

const Register = () => {
  const [currentUserName, setCurrentUserName] = useContext(CurrentUserName);
  organization = currentUserName;
  console.log(organization);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [progressValue, setProgressValue] = useState(89);

  const [age, setAge] = useState(-99);
  const ageHandler = (event) => {
    console.log(typeof event.target.value);
    setAge(event.target.value);
    console.log(age);
  };

  const [gender, setGender] = useState(-99);
  const genderHandler = (event) => {
    console.log(gender);
    setGender(event.target.value);
    setProgressValue(progressValue + 2);
    console.log(gender);
  };

  const [degree, setDegree] = useState(-99);
  const degreeHandler = (event) => {
    setDegree(event.target.value);
    setProgressValue(progressValue + 2);
    console.log(degree);
  };

  const [city, setCity] = useState(-99);
  const cityHandler = (event) => {
    console.log(event.target.value);
    setCity(event.target.value);
    console.log(city);
  };

  const [state, setState] = useState(-99);
  const stateHandler = (event) => {
    console.log(event.target.value);
    setState(event.target.value);
    setProgressValue(progressValue + 1);
    console.log(state);
  };

  const [directPatientCarePercent, setDirectPatientCarePercent] = useState(-99);
  const directPaientCareHandler = (event) => {
    setDirectPatientCarePercent(event.target.value);
    console.log(directPatientCarePercent);
  };

  const [jobYears, setJobYears] = useState(-99);
  const jobYearsHandler = (event) => {
    setJobYears(event.target.value);
    console.log(jobYears);
  };

  const [professionYears, setProfessionYears] = useState(-99);
  const professionYearsHandler = (event) => {
    setProfessionYears(event.target.value);
    console.log(professionYears);
  };

  const [supervisor, setSupervisor] = useState(-99);
  const supervisorHandler = (event) => {
    setSupervisor(event.target.value);
    setProgressValue(progressValue + 1);
    console.log(supervisor);
  };

  const [annualCompensation, setAnnualCompensation] = useState(-99);
  const compensationHandler = (event) => {
    setAnnualCompensation(event.target.value);
    console.log(annualCompensation);
  };

  const [secondJob, setSecondJob] = useState(-99);
  const secondJobHandler = (event) => {
    setSecondJob(event.target.value);
    setProgressValue(progressValue + 2);
    console.log(secondJob);
  };

  const [submitSkip, setSubmitSkip] = useState(false);

  let history = useHistory();
  const registrationDataHandler = (
    age,
    gender,
    degree,
    city,
    state,
    directPatientCarePercent,
    jobYears,
    professionYears,
    supervisor,
    annualCompensation,
    secondJob,
    organization
  ) => {
    if (age === -99) {
      console.log(age);
      skippedItems.push(' Age');
      console.log(skippedItems);
    }
    if (gender === -99) {
      console.log(gender);
      skippedItems.push(' Gender');
      console.log(skippedItems);
    }
    if (degree === -99) {
      skippedItems.push(' Degree');
    }
    if (city === -99) {
      skippedItems.push(' City');
    }
    if (state === -99) {
      skippedItems.push(' State');
    }
    if (directPatientCarePercent === -99) {
      skippedItems.push(' Direct Care');
    }
    if (jobYears === -99) {
      skippedItems.push(' Years in Current Job');
    }
    if (professionYears === -99) {
      skippedItems.push(' Years in Current Profession');
    }
    if (supervisor === -99) {
      skippedItems.push(' Supervisor or Manger');
    }
    if (annualCompensation === -99) {
      skippedItems.push(' Anual Compensation');
    }
    if (secondJob === -99) {
      skippedItems.push(' Second Job');
    }

    console.log(skippedItems);
    if (age === -99 && submitSkip === false) {
      setSubmitSkip(true);
      console.log(submitSkip);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and register without completing the skipped item(s).`
      );
    } else if (gender === -99 && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and register without completing the skipped item(s).`
      );
    } else if (degree === -99 && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and register without completing the skipped item(s).`
      );
    } else if (city === -99 && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and register without completing the skipped item(s).`
      );
    } else if (state === -99 && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and register without completing the skipped item(s).`
      );
    } else if (directPatientCarePercent === -99 && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and register without completing the skipped item(s).`
      );
    } else if (jobYears === -99 && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and register without completing the skipped item(s).`
      );
    } else if (professionYears === -99 && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and register without completing the skipped item(s).`
      );
    } else if (supervisor === -99 && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and register without completing the skipped item(s).`
      );
    } else if (annualCompensation === -99 && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and register without completing the skipped item(s).`
      );
    } else if (secondJob === -99 && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and register without completing the skipped item(s).`
      );
    } else {
      POSTRegistrationData(
        age,
        gender,
        degree,
        city,
        state,
        directPatientCarePercent,
        jobYears,
        professionYears,
        supervisor,
        annualCompensation,
        secondJob,
        organization
      );

      history.push('/thankyousession1');
    }
  };

  return (
    <div className="page">
      <h1>Tell us a bit about yourself ...</h1>
      <div className="formItem formStyle">
        <label
          htmlFor="age"
          className="block text-sm font-medium text-gray-900"
        >
          Age
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="age"
            id="age"
            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=" years"
            onChange={ageHandler}
          />
        </div>
      </div>

      <div className="formItem formStyle">
        <label
          htmlFor="gender"
          className="block text-sm font-medium text-gray-900"
        >
          Gender
        </label>
      </div>

      <div className="formItem formStyle">
        <select
          id="gender"
          name="gender"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md"
          onChange={genderHandler}
        >
          <option> select gender</option>
          <option value="female">female</option>
          <option value="male">male</option>
          <option value="non-binary">non-binary</option>
          <option value="no-answer">prefer not to answer</option>
        </select>
      </div>

      <div>
        <div className="formItem formStyle">
          <label
            htmlFor="degree"
            className="block text-sm font-medium text-gray-900"
          >
            Degree
          </label>
        </div>

        <div className="formItem formStyle">
          <select
            id="degree"
            name="degree"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md"
            onChange={degreeHandler}
          >
            <option> select degree</option>
            <option value="ADN">ADN</option>
            <option value="BSN">BSN</option>
            <option value="MSN">MSN</option>
            <option value="DNP">DNP</option>
            <option value="PhD">PhD</option>
            <option value="other">other</option>
          </select>
        </div>
      </div>

      <div className="formItem formStyle">
        <label
          htmlFor="city"
          className="block text-sm font-medium text-gray-900"
        >
          City in which you Work
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="city"
            id="city"
            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=" enter city"
            onChange={cityHandler}
          />
        </div>
      </div>

      <div>
        <div className="formItem formStyle">
          <label
            htmlFor="state"
            className="block text-sm font-medium text-gray-900"
          >
            State in which you Work
          </label>
        </div>

        <div className="formItem formStyle">
          <select
            id="state"
            name="state"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md"
            onChange={stateHandler}
          >
            <option> select state</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
      </div>

      <div className="formItem formStyle">
        <label
          htmlFor="directPatientCarePercent"
          className="block text-sm font-medium text-gray-900"
        >
          Percent of Your Time Spent in Direct Patient Care
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="directPatientCarePercent"
            id="directPatientCarePercent"
            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=" percent of time"
            onChange={directPaientCareHandler}
          />
        </div>
      </div>

      <div className="formItem formStyle">
        <label
          htmlFor="jobYears"
          className="block text-sm font-medium text-gray-900"
        >
          Years in Current Job
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="jobYears"
            id="jobYears"
            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=" years"
            onChange={jobYearsHandler}
          />
        </div>
      </div>

      <div className="formItem formStyle">
        <label
          htmlFor="professionYears"
          className="block text-sm font-medium text-gray-900"
        >
          Years in Current Profession
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="professionYears"
            id="professionYears"
            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=" years"
            onChange={professionYearsHandler}
          />
        </div>
      </div>

      <div className="formItem formStyle">
        <label
          htmlFor="supervisor"
          className="block text-sm font-medium text-gray-900"
        >
          Supervisor or Manager
        </label>
      </div>

      <div className="formItem formStyle">
        <select
          id="supervisor"
          name="supervisor"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md"
          onChange={supervisorHandler}
        >
          <option> select yes or no</option>
          <option value="yes">yes</option>
          <option value="no">no</option>
        </select>
      </div>

      <div className="formItem formStyle">
        <label
          htmlFor="annualCompensation"
          className="block text-sm font-medium text-gray-900"
        >
          Annual Compensation
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="annualCompensation"
            id="annualCompensation"
            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=" annual compensation in dollars"
            onChange={compensationHandler}
          />
        </div>
      </div>

      <div className="formItem formStyle">
        <label
          htmlFor="secondJob"
          className="block text-sm font-medium text-gray-900"
        >
          Do you have a second job?
        </label>
      </div>

      <div className="formItem formStyle">
        <select
          id="secondJob"
          name="secondJob"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md"
          onChange={secondJobHandler}
        >
          <option> select yes or no</option>
          <option value="yes">yes</option>
          <option value="no">no</option>
        </select>
      </div>

      <div className="buttonDiv">
        <button
          style={{ margin: '40px 0 25px 0' }}
          type="submit"
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
          onClick={() =>
            registrationDataHandler(
              age,
              gender,
              degree,
              city,
              state,
              directPatientCarePercent,
              jobYears,
              professionYears,
              supervisor,
              annualCompensation,
              secondJob,
              organization
            )
          }
        >
          Submit
        </button>
      </div>
      <div className="progressBar">
        <ProgressBar
          color={'green'}
          width={'300px'}
          value={progressValue}
          max={100}
        />
      </div>
    </div>
  );
};

export default Register;
