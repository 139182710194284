import { Fragment, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import Content from './Content';

import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { useAuth } from '../hooks/useAuth';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Navigation(props) {
  const history = useHistory();
  const auth = useAuth();

  const user = auth.user;
  if (!user) history.push('/login');

  const [state, setState] = useState({
    nav: [
      // { name: 'Nudges', href: '/nudges', current: false },
      // { name: 'Consent', href: '/consent', current: false },
      // { name: 'Register', href: '/register', current: false },
      // { name: 'PsyCap', href: '/pcq', current: false },
      // { name: 'Stress', href: '/stress', current: false },
      // { name: 'Embeddedness', href: '/embeddedness', current: false },
      // { name: 'Search', href: '/search', current: false },
      // { name: 'Welcome', href: '/', current: false },
      // { name: 'Help', href: '/help', current: false },
    ],
  });

  const updateNav = (href) => {
    const newNav = state.nav.map((item) => {
      if (item.href === href) {
        return { ...item, current: true };
      }
      return { ...item, current: false };
    });
    setState({ nav: newNav });
  };

  const redirectToURL = (page) => {
    const url = '/' + page;
    history.push(url);
  };
  console.log(user);

  return (
    <div className="relative bg-gray-50 overflow-hidden">
      <div
        className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
        aria-hidden="true"
      >
        <div className="relative h-full max-w-7xl mx-auto">
          <svg
            className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
            />
          </svg>
        </div>
      </div>

      <div className="relative pt-4 pb-8 sm:pb-0">
        <Popover>
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <nav
              className="relative flex items-center justify-between sm:h-10 md:justify-center"
              aria-label="Global"
            >
              <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <button
                    onClick={() => {
                      redirectToURL('');
                    }}
                  >
                    <span className="inline align-middle text-2xl tracking-tight font-bold text-gray-600">
                      Nurse Career Survey
                    </span>
                  </button>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="hidden md:flex md:space-x-10">
                {state.nav.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    onClick={() => updateNav(item.href)}
                    className={classNames(
                      item.current
                        ? 'border-green-500 text-green-600'
                        : 'text-white hover:border-gray-300 hover:text-gray-700',
                      'ml-8 font-medium text-gray-500 hover:text-green-800 transition duration-150 ease-in-out'
                    )}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
              <div className="md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                {!user && (
                  <span className="inline-flex rounded-md shadow">
                    <button
                      onClick={() => {
                        redirectToURL('login');
                      }}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-50"
                    >
                      Log in
                    </button>
                  </span>
                )}
                {user && (
                  <span className="inline-flex rounded-md shadow">
                    <button
                      onClick={() => {
                        auth.logout();
                      }}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-50"
                    >
                      Log out
                    </button>
                  </span>
                )}
              </div>
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark-green-600.svg"
                      alt=""
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3">
                  {state.nav.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      onClick={() => updateNav()}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
                <button
                  onClick={() => {
                    redirectToURL('login');
                  }}
                  className="block w-full px-5 py-3 text-center font-medium text-green-600 bg-gray-50 hover:bg-gray-100"
                >
                  Log in
                </button>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <Content />
      </div>
    </div>
  );
}
