import { useHistory } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

import './page.css';

window.scrollTo(0, 0);

let minutes = 0;
let totalMinutes = 0;

const ThankYouSession1 = () => {
  const storedStartTime = parseInt(localStorage.getItem('storedStartTime'));
  console.log(storedStartTime);
  const totalTime = Date.now() - storedStartTime;
  console.log(totalTime);

  minutes = totalTime / 60000;
  totalMinutes = minutes.toFixed(2);
  console.log(totalMinutes);

  var xhr = new XMLHttpRequest();
  xhr.open(
    'PUT',
    'https://6jk00r88v6.execute-api.us-east-1.amazonaws.com/dev/totalminutes'
  );
  xhr.onreadystatechange = function (event) {
    console.log(event.target.response);
  };
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(
    JSON.stringify({
      id: window.currentId,
      totalMinutes: totalMinutes,
    })
  );

  let history = useHistory();
  const auth = useAuth();

  const user = auth.user;
  if (!user) history.push('/login');

  return (
    <div className="page">
      <h1>THANK YOU</h1>
      <p className="instructions">
        Thank you for your participation. You have been generous with your time.
        If you have questions or concerns, contact the prinicipal invesitigator,
        Eric Parmenter, at eparmenter.ego.thechicagoschool.edu.
        <p className="center">To close your session, please log out.</p>
      </p>
      <div className="buttonDiv">
        <button
          type="button"
          className="button inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => {
            auth.logout();
          }}
        >
          Log Out
        </button>
      </div>
    </div>
  );
};

export default ThankYouSession1;
