import { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './Auth/PrivateRoute';
import Consent from '../pages/Consent';
import Register from '../pages/Register';
import PCQ from '../pages/PCQ';
import Embeddedness from '../pages/Embeddedness';
import Welcome from '../pages/Welcome';
import ThankYouSession1 from '../pages/ThankYouSession1';
import Nudges from '../pages/Nudges';
import Help from '../pages/Help';
import SMBM from '../pages/SMBM';
import PeakEndRule from '../pages/PeakEndRule';
import SatisfationSurvey from '../pages/SatisfactionSurvey';

/**
 * Layout component which holds the main app content switched via the
 * router
 * @returns {JSX.Element}
 * @constructor
 */
export default function Content() {
  return (
    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
      <div className="py-2">
        <Switch>
          <PrivateRoute exact path="/" component={Welcome} />
          <PrivateRoute path="/consent" component={Consent} />
          <PrivateRoute path="/register" component={Register} />
          <PrivateRoute path="/peakend" component={PeakEndRule} />
          <PrivateRoute path="/pcq" component={PCQ} />
          <PrivateRoute path="/embeddedness" component={Embeddedness} />
          <PrivateRoute path="/SMBM" component={SMBM} />
          <PrivateRoute path="/thankyousession1" component={ThankYouSession1} />
          <PrivateRoute path="/nudges" component={Nudges} />
          <PrivateRoute
            path="/satisfactionsurvey"
            component={SatisfationSurvey}
          />
          <Route path="/welcome" component={Welcome} />
          <Route path="/help" component={Help} />
        </Switch>
      </div>
    </main>
  );
}
