import { useHistory } from 'react-router-dom';

import './page.css';

const Welcome = () => {
  const startTime = Date.now();
  localStorage.setItem('storedStartTime', startTime);
  console.log(localStorage.getItem('storedStartTime'));

  let history = useHistory();

  const sessionHandler = () => {
    history.push('/consent');
  };

  return (
    <div className="page">
      <h1>WELCOME</h1>
      <p className="instructions">
        Thank you for your participation in the Nurse Occupational Embeddedness
        Study. The study consists of five brief surveys, which contain a total
        of 50 questions.The entire session will take approximately 15 minutes to
        complete. Please complete the entire survey in one sitting. To begin,
        click the "Consent" button. If you have questions or concerns, contact
        the principal invesitigator, Eric Parmenter, at
        eparmenter.ego.thechicagoschool.edu. If you experience issues with
        logging in or with the website, email support@dropBysolutions.com.
      </p>
      <div className="buttonDiv">
        <button
          type="button"
          className="button inline-flex items-center px-7 py-4 border border-transparent text-lg leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => sessionHandler()}
        >
          Consent
        </button>
      </div>
    </div>
  );
};

export default Welcome;
