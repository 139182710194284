import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

import './page.css';

import SatisfactionRatings from '../utilities/SatisfationRatings';

import ProgressBar from '../components/Progress';
import '../components/progress.css';

import PUTEndTime from '../utilities/PUTEndTime';

const satisfactionRatings = [
  { id: 'completely-disagree', title: 'Completely Disagree' },
  { id: 'strongly-disagree', title: 'Strongly Disagree' },
  { id: 'disagree', title: 'Disagree' },
  { id: 'mostly-disagree', title: 'Mostly Disagree' },
  { id: 'somewhat-disagree', title: 'Somewhat Disagree' },
  { id: 'neither', title: 'Neither Disagree or Agree ' },
  { id: 'somewhat-agree', title: 'Somewhat Agree' },
  { id: 'mostly-agree', title: 'Mostly Agree' },
  { id: 'agree', title: 'Agree' },
  { id: 'strongly-agree', title: 'Strongly Agree' },
  { id: 'completely-agree', title: 'Completely Agree' },
];

// const turnoverIntentRatings = [
//   {
//     id: 'never',
//     title: 'I never think about leaving this organization.',
//   },
//   {
//     id: 'occasionally',
//     title: 'I occasionally think about leaving this organization.',
//   },
//   {
//     id: 'often',
//     title:
//       'I often think about leaving this organization and I would like to leave in the next six months.',
//   },
//   {
//     id: 'actively-looking',
//     title:
//       'I am currently actively looking for another job and will notify my empoyer after I am offered another job.',
//   },
// ];

// const leaveProfessionIntentRatings = [
//   {
//     id: 'never',
//     title: 'I never think about leaving the nursing profession.',
//   },
//   {
//     id: 'occasionally',
//     title: 'I occasionally think about leaving the nursing profession.',
//   },
//   {
//     id: 'often',
//     title:
//       'I often think about leaving the nursing profession and I would like to leave in the next six months.',
//   },
//   {
//     id: 'actively-looking',
//     title: 'I am currently actively planning to leave the nursing profession.',
//   },
// ];

const SatisfactionSurvey = () => {
  const [progressValue, setProgressValue] = useState(65);

  const [paySatisfaction, setpaySatisfaction] = useState(false);
  const [supervisorSatisfaction, setSupervisorSatifaction] = useState(false);
  const [environmentSatisfaction, setEnvironmentSatisfaction] = useState(false);
  const [organizationSatifaction, setOrganizationSatifaction] = useState(false);
  const [turnoverIntent, setTurnoverIntent] = useState(false);
  const [leaveProfessionIntent, setLeaveProfessionIntent] = useState(false);

  const [submitSkip, setSubmitSkip] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();

  const ratingHandler = (event) => {
    // console.dir(event.target);
    const rating = event.target.id;
    const question = event.target.name;

    if (question === 'paySatisfaction') {
      setpaySatisfaction(question);
    }
    if (question === 'supervisorSatisfaction') {
      setSupervisorSatifaction(question);
    }
    if (question === 'environmentSatisfaction') {
      setEnvironmentSatisfaction(question);
    }
    if (question === 'organizationSatifaction') {
      setOrganizationSatifaction(question);
    }
    if (question === 'turnoverIntent') {
      setTurnoverIntent(question);
    }

    if (question === 'leaveProfessionIntent') {
      setLeaveProfessionIntent(question);
    }

    setProgressValue(progressValue + 2);
    SatisfactionRatings(question, rating);
  };

  const sessionHandler = () => {
    const skippedItems = [];

    if (paySatisfaction === false) {
      skippedItems.push(' 1');
    }
    if (supervisorSatisfaction === false) {
      skippedItems.push(' 2');
    }
    if (environmentSatisfaction === false) {
      skippedItems.push(' 3');
    }
    if (organizationSatifaction === false) {
      skippedItems.push(' 4');
    }
    if (turnoverIntent === false) {
      skippedItems.push(' 5');
    }
    if (leaveProfessionIntent === false) {
      skippedItems.push(' 5');
    }

    if (paySatisfaction === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (supervisorSatisfaction === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (environmentSatisfaction === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (organizationSatifaction === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (turnoverIntent === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (leaveProfessionIntent === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else {
      history.push('/peakend');
    }
  };

  return (
    <div>
      <ScrollToTop smooth />
      <div className="page">
        <h1>Pay, Boss, Job, Org, and Turnover Intent*</h1>
        <p>Instructions:</p>
        <p>
          Below are statements regarding your current job satisfaction. Use the
          following scale to indicate your level of agreement or disagreement
          with each statement: completely disagree - strongly disagree -
          disagree - mostly disagree - somewhat disagree - neither disagree or
          agree - somewhat agree - mostly agree - agree - strongly agree -
          completely agree
        </p>
        <hr />

        <p>1. I'm satisfied with my pay.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {satisfactionRatings.map((satisfactionRating) => (
              <div
                key={'paySatisfaction' + satisfactionRating.id}
                className="flex items-center"
              >
                <input
                  id={'paySatisfaction' + satisfactionRating.id}
                  name="paySatisfaction"
                  type="radio"
                  // defaultChecked={satisfactionRating.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'paySatisfaction' + satisfactionRating.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {satisfactionRating.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>2. I'm satisfied with my supervisor.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {satisfactionRatings.map((satisfactionRating) => (
              <div
                key={'supervisorSatisfaction' + satisfactionRating.id}
                className="flex items-center"
              >
                <input
                  id={'supervisorSatisfaction' + satisfactionRating.id}
                  name="supervisorSatisfaction"
                  type="radio"
                  // defaultChecked={satisfactionRating.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'supervisorSatisfaction' + satisfactionRating.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {satisfactionRating.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          3. I’m satisfied with the nature of my job (shift work conditions,
          behavior of colleagues).
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {satisfactionRatings.map((satisfactionRating) => (
              <div
                key={'environmentSatisfaction' + satisfactionRating.id}
                className="flex items-center"
              >
                <input
                  id={'environmentSatisfaction' + satisfactionRating.id}
                  name="environmentSatisfaction"
                  type="radio"
                  // defaultChecked={satisfactionRating.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'environmentSatisfaction' + satisfactionRating.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {satisfactionRating.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          4. I’m satisfied with my organization (organizational goals, support,
          fosters confidence and trust).
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {satisfactionRatings.map((satisfactionRating) => (
              <div
                key={'organizationSatifaction' + satisfactionRating.id}
                className="flex items-center"
              >
                <input
                  id={'organizationSatifaction' + satisfactionRating.id}
                  name="organizationSatifaction"
                  type="radio"
                  // defaultChecked={satisfactionRating.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'organizationSatifaction' + satisfactionRating.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {satisfactionRating.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          5. In the next six months, I intend to look for a nursing job at
          another health care organization.
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {satisfactionRatings.map((satisfactionRating) => (
              <div
                key={'turnoverIntent' + satisfactionRating.id}
                className="flex items-center"
              >
                <input
                  id={'turnoverIntent' + satisfactionRating.id}
                  name="turnoverIntent"
                  type="radio"
                  // defaultChecked={turnoverIntentRating.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'turnoverIntent' + satisfactionRating.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {satisfactionRating.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          6. In the next six months, I intend to leave the nursing profession.
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {satisfactionRatings.map((satisfactionRating) => (
              <div
                key={'leaveProfessionIntent' + satisfactionRating.id}
                className="flex items-center"
              >
                <input
                  id={'leaveProfessionIntent' + satisfactionRating.id}
                  name="leaveProfessionIntent"
                  type="radio"
                  // defaultChecked={turnoverIntentRating.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'leaveProfessionIntent' + satisfactionRating.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {satisfactionRating.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p className="citation">
          *Questions 1-4 Source: Parmenter, E. (2022). Pay, Boss, Job and
          Organization Global Questions. Question 5 source: Nelson, J. W.,
          Milutinović, D., Kasimovskaya, N., SİS ÇELİK, A., KILIÇ, D., Gözüm,
          S., & Vrbnjak, D. (2022). The Profile of Caring: An internationally
          tested model to assess and support nurses during pandemic. Creative
          Nursing, 28(1).
        </p>
      </div>
      <div className="buttonDiv">
        <button
          type="button"
          className="button inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => sessionHandler()}
        >
          Submit
        </button>
      </div>
      <p className="page__count">Page 4 of 5</p>
      <div className="progressBar">
        <ProgressBar
          color={'green'}
          width={'300px'}
          value={progressValue}
          max={100}
        />
      </div>
    </div>
  );
};

export default SatisfactionSurvey;
