import React, { useState, useEffect, useContext, createContext } from 'react';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';

const userPoolId = process.env.USER_POOL_ID;
const clientId = process.env.USER_POOL_CLIENT_ID;

const userPool = new CognitoUserPool({
  UserPoolId: userPoolId,
  ClientId: clientId,
});

const authContext = createContext();

/**
 * Check local storage for existing user data
 * @returns {null|any}
 */
const localAuth = () => {
  const user = localStorage.getItem('user');

  if (user) {
    return JSON.parse(user);
  }

  return null;
};

/**
 * Provider component for authentication hook
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export function ProvideAuth({ children }) {
  const user = localAuth();
  const auth = useProvideAuth(user);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/**
 * Hook used by application components
 * @returns {unknown}
 */
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth(initialState) {
  const [user, setUser] = useState(initialState);

  useEffect(() => {
    const localAuth = async () => {
      try {
        const user = await getCurrentUser();
        if (user) {
          setUser(user);
        }
      } catch (e) {
        console.log('No User Found');
      }
    };
    localAuth();
    // eslint-disable-next-line
  }, []);

  /**
   * Login using cognito identity provider
   * @param username
   * @param password
   * @returns {Promise<unknown>}
   */
  const login = async (username, password) => {
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const user = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: () => {
          user.globalSignOut({
            onSuccess: () => {
              // Wait 2 seconds after the global sign out until authenticating again.
              // This is to prevent the new token from being immediately invalidated.
              setTimeout(() => {
                user.authenticateUser(authDetails, {
                  onSuccess: async (session) => {
                    await fetchUserData();
                    const user = await constructUser(session);
                    console.dir(user);
                    setUser(user);
                    localStorage.setItem('user', JSON.stringify(user));
                    resolve(true);
                  },
                  onFailure: (err) => {
                    reject(err);
                  },
                });
              }, 2000);
            },
            onFailure: (err) => {
              reject(err);
            },
          });
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (userAttributes) => {
          userAttributes.FORCE_PASSWORD_CHANGE = true;
          resolve(userAttributes);
        },
      });
    });
  };

  /**
   * Handles first time login and new password reset
   * @param username
   * @param password
   * @param newPassword
   * @returns {Promise<unknown>}
   */
  const newPasswordChange = ({ username, password, newPassword }) => {
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const user = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: async (session) => {
          await fetchUserData();
          const user = constructUser(session);
          setUser(user);
          resolve(true);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (userAttributes) => {
          delete userAttributes.email_verified;
          Object.keys(userAttributes).forEach((attributeKey) => {
            if (attributeKey.startsWith('custom:')) {
              delete userAttributes[attributeKey];
            }
          });
          user.completeNewPasswordChallenge(newPassword, userAttributes, {
            onSuccess: async (session) => {
              await fetchUserData();
              const user = constructUser(session);
              setUser(user);
              resolve(true);
            },
            onFailure: (err) => {
              reject(err);
            },
          });
        },
      });
    });
  };

  /**
   * Logout user cognito identity provider. Clear local storage
   * @returns {Promise<void>}
   */
  const logout = async () => {
    const user = userPool.getCurrentUser();
    user.signOut();
    window.localStorage.clear();
    setUser(null);
  };

  const getCurrentUser = () => {
    const cognitoUser = userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
      if (cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            const user = constructUser(session);
            resolve(user);
          }
        });
      } else {
        setUser(null);
        reject(new Error('No user found'));
      }
    });
  };

  // Return the user object and auth methods
  return {
    user,
    login,
    newPasswordChange,
    getCurrentUser,
    logout,
  };
}

/**
 * Fetches user data from authenticated session
 * @returns {Promise<unknown>}
 */
const fetchUserData = () => {
  const cognitoUser = userPool.getCurrentUser();
  return new Promise((resolve, reject) => {
    if (cognitoUser != null) {
      cognitoUser.getSession((err) => {
        if (err) {
          reject(err);
        } else {
          cognitoUser.getUserData(
            (attrErr, userData) => {
              if (attrErr) {
                reject(attrErr);
              } else {
                resolve(userData);
              }
            },
            { bypassCache: true }
          );
        }
      });
    } else {
      reject(Error('No user'));
    }
  });
};

/**
 * Builds user object for store
 * @param session
 * @returns {{tokens: {IdToken: string, RefreshToken: string, AccessToken: string}, attributes: {}}}
 */
const constructUser = (session) => {
  const { sub, 'custom:organization_slug': organizationId } = session
    .getIdToken()
    .decodePayload();

  return {
    role: session.getAccessToken().decodePayload()['cognito:groups'][0],
    username: session.getAccessToken().decodePayload().username,
    tokens: {
      IdToken: session.getIdToken().getJwtToken(),
      AccessToken: session.getAccessToken().getJwtToken(),
      RefreshToken: session.getRefreshToken().getToken(),
    },
    attributes: {
      organizationId,
      id: sub,
    },
  };
};
