import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

import './page.css';
import EmbedRatings from '../utilities/EmbedRatings';
import ProgressBar from '../components/Progress';
import '../components/progress.css';

const embed1responses = [
  { id: 'strongly-disagree', title: 'strongly disagree' },
  { id: 'disagree', title: 'disagree' },
  { id: 'somewhat-disagree', title: 'somewhat disagree' },
  { id: 'somewhat-agree', title: 'somewhat agree' },
  { id: 'agree', title: 'agree' },
  { id: 'strongly-agree', title: 'strongly agree' },
];

const embed2responses = [
  { id: 'strongly-disagree', title: 'strongly disagree' },
  { id: 'disagree', title: 'disagree' },
  { id: 'somewhat-disagree', title: 'somewhat disagree' },
  { id: 'somewhat-agree', title: 'somewhat agree' },
  { id: 'agree', title: 'agree' },
  { id: 'strongly-agree', title: 'strongly agree' },
];

const embed3responses = [
  { id: 'strongly-disagree', title: 'strongly disagree' },
  { id: 'disagree', title: 'disagree' },
  { id: 'somewhat-disagree', title: 'somewhat disagree' },
  { id: 'somewhat-agree', title: 'somewhat agree' },
  { id: 'agree', title: 'agree' },
  { id: 'strongly-agree', title: 'strongly agree' },
];

const embed4responses = [
  { id: 'strongly-disagree', title: 'strongly disagree' },
  { id: 'disagree', title: 'disagree' },
  { id: 'somewhat-disagree', title: 'somewhat disagree' },
  { id: 'somewhat-agree', title: 'somewhat agree' },
  { id: 'agree', title: 'agree' },
  { id: 'strongly-agree', title: 'strongly agree' },
];

const embed5responses = [
  { id: 'strongly-disagree', title: 'strongly disagree' },
  { id: 'disagree', title: 'disagree' },
  { id: 'somewhat-disagree', title: 'somewhat disagree' },
  { id: 'somewhat-agree', title: 'somewhat agree' },
  { id: 'agree', title: 'agree' },
  { id: 'strongly-agree', title: 'strongly agree' },
];

const embed6responses = [
  { id: 'strongly-disagree', title: 'strongly disagree' },
  { id: 'disagree', title: 'disagree' },
  { id: 'somewhat-disagree', title: 'somewhat disagree' },
  { id: 'somewhat-agree', title: 'somewhat agree' },
  { id: 'agree', title: 'agree' },
  { id: 'strongly-agree', title: 'strongly agree' },
];

const embed7responses = [
  { id: 'strongly-disagree', title: 'strongly disagree' },
  { id: 'disagree', title: 'disagree' },
  { id: 'somewhat-disagree', title: 'somewhat disagree' },
  { id: 'somewhat-agree', title: 'somewhat agree' },
  { id: 'agree', title: 'agree' },
  { id: 'strongly-agree', title: 'strongly agree' },
];

const Embeddedness = () => {
  const [progressValue, setProgressValue] = useState(20);

  const [embed1, setembed1] = useState(false);
  const [embed2, setembed2] = useState(false);
  const [embed3, setembed3] = useState(false);
  const [embed4, setembed4] = useState(false);
  const [embed5, setembed5] = useState(false);
  const [embed6, setembed6] = useState(false);
  const [embed7, setembed7] = useState(false);

  const [submitSkip, setSubmitSkip] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();

  const ratingHandler = (event) => {
    // console.dir(event.target);
    const rating = event.target.id;
    const question = event.target.name;

    if (question === 'embed1') {
      setembed1(question);
    }
    if (question === 'embed2') {
      setembed2(question);
    }
    if (question === 'embed3') {
      setembed3(question);
    }
    if (question === 'embed4') {
      setembed4(question);
    }
    if (question === 'embed5') {
      setembed5(question);
    }
    if (question === 'embed6') {
      setembed6(question);
    }
    if (question === 'embed7') {
      setembed7(question);
    }
    setProgressValue(progressValue + 2);
    EmbedRatings(question, rating);
  };

  const sessionHandler = () => {
    const skippedItems = [];

    if (embed1 === false) {
      skippedItems.push(' 1');
    }
    if (embed2 === false) {
      skippedItems.push(' 2');
    }
    if (embed3 === false) {
      skippedItems.push(' 3');
    }
    if (embed4 === false) {
      skippedItems.push(' 4');
    }
    if (embed5 === false) {
      skippedItems.push(' 5');
    }
    if (embed6 === false) {
      skippedItems.push(' 6');
    }
    if (embed7 === false) {
      skippedItems.push(' 7');
    }

    if (embed1 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (embed2 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (embed3 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (embed4 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (embed5 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (embed6 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (embed7 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else {
      history.push('/SMBM');
    }
  };

  return (
    <div>
      <ScrollToTop smooth />
      <div className="page">
        <h1>Job Embeddedness*</h1>
        <p>Instructions:</p>
        <p>
          After considering both work related (such as relationships, fit with
          job, benefits) and nonwork related factors (such as neighbors,
          hobbies, community perks), please rate your agreement with the
          statements below.
        </p>
        <hr />
        <p>1. I feel attached to this organization.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {embed1responses.map((embed1response) => (
              <div
                key={'embed1' + embed1response.id}
                className="flex items-center"
              >
                <input
                  id={'embed1' + embed1response.id}
                  name="embed1"
                  type="radio"
                  // defaultChecked={embed1response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'embed1' + embed1response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {embed1response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>2. It would be difficult for me to leave this organization.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {embed2responses.map((embed2response) => (
              <div
                key={'embed2' + embed2response.id}
                className="flex items-center"
              >
                <input
                  id={'embed2' + embed2response.id}
                  name="embed2"
                  type="radio"
                  // defaultChecked={embed2response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'embed2' + embed2response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {embed2response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>3. I’m too caught up in this organization to leave.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {embed3responses.map((embed3response) => (
              <div
                key={'embed3' + embed3response.id}
                className="flex items-center"
              >
                <input
                  id={'embed3' + embed3response.id}
                  name="embed3"
                  type="radio"
                  // defaultChecked={embed3response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'embed3' + embed3response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {embed3response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>4. I feel tied to this organization.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {embed4responses.map((embed4response) => (
              <div
                key={'embed4' + embed4response.id}
                className="flex items-center"
              >
                <input
                  id={'embed4' + embed4response.id}
                  name="embed4"
                  type="radio"
                  // defaultChecked={embed4response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'embed4' + embed4response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {embed4response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>5. I simply could not leave the organization that I work for.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {embed5responses.map((embed5response) => (
              <div
                key={'embed5' + embed5response.id}
                className="flex items-center"
              >
                <input
                  id={'embed5' + embed5response.id}
                  name="embed5"
                  type="radio"
                  // defaultChecked={embed5response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'embed5' + embed5response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {embed5response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>6. It would be easy for me to leave this organization.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {embed6responses.map((embed6response) => (
              <div
                key={'embed6' + embed6response.id}
                className="flex items-center"
              >
                <input
                  id={'embed6' + embed6response.id}
                  name="embed6"
                  type="radio"
                  // defaultChecked={embed6response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'embed6' + embed6response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {embed6response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>7. I am tightly connected to this organization.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {embed7responses.map((embed7response) => (
              <div
                key={'embed7' + embed7response.id}
                className="flex items-center"
              >
                <input
                  id={'embed7' + embed7response.id}
                  name="embed7"
                  type="radio"
                  // defaultChecked={embed7response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'embed7' + embed7response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {embed7response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p className="citation">
          *Crossley, Bennett, R. J., Jex, S. M., & Burnfield, J. L. (2011).
          “Development of a global measure of job embeddedness and integration
          into a traditional model of voluntary turnover”: Clarification to
          Crossley et al. (2007). Journal of Applied Psychology, 96(6),
          1316–1316. https://doi.org/10.1037/a0025569
        </p>
      </div>
      <div className="buttonDiv">
        <button
          type="button"
          className="button inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => sessionHandler()}
        >
          Submit
        </button>
      </div>
      <p className="page__count">Page 2 of 5</p>
      <div className="progressBar">
        <ProgressBar
          color={'green'}
          width={'300px'}
          value={progressValue}
          max={100}
        />
      </div>
    </div>
  );
};

export default Embeddedness;
