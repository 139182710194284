import PUTSatisfactionRatings from './PUTSatisfactionRatings';

const satisfactionRatings = [-99, -99, -99, -99, -99, -99];
const questionnaire = 'satisfaction';

const SatisfactionRatings = (question, rating) => {
  // convert ratings into values
  console.log(question);

  // shiftStress
  if (rating === question + 'completely-disagree') {
    rating = 1;
  }
  if (rating === question + 'strongly-disagree') {
    rating = 2;
  }
  if (rating === question + 'disagree') {
    rating = 3;
  }
  if (rating === question + 'mostly-disagree') {
    rating = 4;
  }
  if (rating === question + 'somewhat-disagree') {
    rating = 5;
  }
  if (rating === question + 'neither') {
    rating = 6;
  }
  if (rating === question + 'somewhat-agree') {
    rating = 7;
  }
  if (rating === question + 'mostly-agree') {
    rating = 8;
  }
  if (rating === question + 'agree') {
    rating = 9;
  }
  if (rating === question + 'strongly-agree') {
    rating = 10;
  }
  if (rating === question + 'completely-agree') {
    rating = 11;
  }

  if (question === 'paySatisfaction') {
    satisfactionRatings[0] = rating;
  }

  if (question === 'supervisorSatisfaction') {
    satisfactionRatings[1] = rating;
  }

  if (question === 'environmentSatisfaction') {
    satisfactionRatings[2] = rating;
  }

  if (question === 'organizationSatifaction') {
    satisfactionRatings[3] = rating;
  }

  if (question === 'turnoverIntent') {
    satisfactionRatings[4] = rating;
  }

  if (question === 'leaveProfessionIntent') {
    satisfactionRatings[5] = rating;
  }

  console.log(questionnaire, satisfactionRatings);
  PUTSatisfactionRatings(satisfactionRatings);
};

export default SatisfactionRatings;
