const PUTSatisfactionRatings = (satisfactionRatings) => {
  console.log(window.currentId, satisfactionRatings);
  var xhr = new XMLHttpRequest();
  xhr.open(
    'PUT',
    'https://6jk00r88v6.execute-api.us-east-1.amazonaws.com/dev/satisfaction'
  );
  xhr.onreadystatechange = function (event) {
    console.log(event.target.response);
  };
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(
    JSON.stringify({
      id: window.currentId,
      satisfactionRatings: satisfactionRatings,
    })
  );
};

export default PUTSatisfactionRatings;
