import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import './page.css';
import './consent.css';

const Consent = () => {
  const notificationMethods = [
    { id: 'continue', title: 'agree & continue' },
    { id: 'decline', title: 'decline & logout' },
  ];

  const [consent, setConsent] = useState('');
  let history = useHistory();
  const auth = useAuth();

  const consentHandler = (event) => {
    console.dir(event.target);
    const agree = event.target.id;
    console.log(agree);
    setConsent(agree);
    if (agree === 'decline') {
      auth.logout();
      history.push('/login');
    }
  };

  function sessionHandler() {
    if (consent === '') {
      alert("If you wish to continue, please check 'yes'.");
    } else {
      history.push('/pcq');
    }
  }
  return (
    <div className="page">
      <div className="consent consent__width">
        <h1>Consent</h1>

        <p>
          <span className="bold">Investigator(s):</span> Eric Parmenter, MBA{' '}
        </p>

        <p>
          <span className="bold">Study Title:</span> I HAD A BAD DAY BUT
          TOMORROW I WILL CARRY ON: NURSE BURNOUT AND THE RELATIONSHIP BETWEEN
          PSYCHOLOGICAL CAPITAL AND JOB EMBEDDEDNESS
        </p>
        <p>
          I am a student at The Chicago School of Professional Psychology. This
          study is being conducted as a part of my dissertation requirement for
          the Ph.D. in Business Psychology.{' '}
        </p>
        <p>
          <span className="bold">
            I am asking you to participate in a research study about
          </span>{' '}
          the relationship between psychological capital (PsyCap) and burnout
          with job embeddedness in U.S. nurses. You will be asked to complete a
          survey that takes about 15 minutes to complete. This study considers
          whether having a recent bad day influences PsyCap, burnout, and job
          embeddedness by exploring a key behavioral economics facet called the
          peak-end rule <span className="bold">This may cause </span> a
          potential risk of loss of confidentiality and the risk that questions
          may be regarded as sensitive or invasive. Although you will not
          benefit from this study, this information may be used by your employer
          to help understand burnout and job embeddedness for nurses with the
          hopes of learning ways to improve hospital organizations'
          relationships with nurses.
        </p>
        <p>
          <span className="bold">Purpose:</span> The shortage of nurses working
          in hospitals is a significant and growing concern, not only for those
          hospitals and health systems that employ nurses but for societies
          around the world that rely on adequately trained nurses to care for
          patients. Understanding job embeddedness in nursing can help
          healthcare executives and nurse leaders develop and implement
          effective workforce strategies to increase job embeddedness, as a way
          to reduce turnover.
        </p>
        <p>
          <span className="bold">
            Please take your time to read the entire statement, and feel free to
            ask any questions before signing this document. You may email the
            researcher at eparmenter.ego.thechicagoschool.edu.
          </span>{' '}
        </p>
        <p>
          <span className="bold">Procedures:</span> I am asking you to
          participate in a research study about psychological capital (PsyCap),
          burnout, job embeddedness, perception of your job, and information
          about your most recent shift. You will be asked to complete a survey
          of 50 questions on the following five pages. This will take
          approximately 15 minutes to complete. The survey will be conducted
          through a custom website built for this study. The survey platform is
          a custom-designed website in a secure environment through Amazon Web
          Services with two-factor authentication and encrypted data.
        </p>
        <p>
          <span className="bold">Risks to Participants:</span> The potential
          risk of participating in this survey is the risk of loss of
          confidentiality, and questions may be regarded as sensitive or
          invasive information. The measures that will be taken to minimize the
          risks will include only participants aged 18 or older. The survey
          platform was developed such that participants cannot enter or write in
          any information that may provide identification. Only
          deidentified/coded data will be shared with participating hospitals.
          No other identifying data will be shared with participating hospitals.
          No IP addresses will be collected. Participants may withdraw from the
          survey at any time if they feel overwhelmed.
        </p>
        <p>
          <span className="bold">Benefits to Participants:</span> Although you
          may not benefit directly from participating in this study, the summary
          information may be used by your employer to help to understand burnout
          and job embeddedness for nurses with the hopes of learning ways to
          improve hospital organizations' relationships with nurses.{' '}
        </p>
        <p>
          <span className="bold">Alternatives to Participation:</span>{' '}
          Participation in this study is voluntary. You may withdraw from study
          participation at any time without any penalty.{' '}
        </p>
        <p>
          <span className="bold">Confidentiality</span>: During this study,
          information will be collected about you for the purpose of this
          research. On the registration page, you will be asked a series of
          demographic questions that include age, gender (male, female,
          non-binary, choose not to answer), degree (CNA, ADN, BSN, DNP, PhD,
          other), city and state where you work, percentage of time spent in
          direct patient care, years in current job and, years in current
          profession, whether you are a supervisor or manager, annual
          compensation, and whether you have a second job. The website and
          survey administration are conducted by dropBy, LLC. The data is stored
          in a secure Amazon Web Service (AWS) cloud-based server. All data is
          de-identified/coded. The data will be evaluated using IBM SPSS
          software and Microsoft Excel. I will be the only one with access to
          the study data.
        </p>
        <p>
          Research materials will be kept for a minimum of five years after
          publication per American Psychological Association (APA) guidelines.
          It is possible that your data may be used for future research or
          distributed to another researcher without your consent. However,
          information that could identify you will be removed.{' '}
        </p>
        <p>
          Your research records may be reviewed by federal agencies whose
          responsibility is to protect human subjects participating in research,
          including the Office of Human Research Protections (OHRP) and by
          representatives from The Chicago School of Professional Psychology
          Institutional Review Board, a committee that oversees research.{' '}
        </p>
        <p>
          <span className="bold">Questions/Concerns:</span> If you have
          questions related to the procedures described in this document, please
          contact Eric Parmenter at eparmenter.ego.thechicagoschool.edu or Maria
          Malayter, Ph.D. at mmalayter@thechicagoschool.edu.
        </p>
        <p>
          If you have questions concerning your rights in this research study,
          you may contact the Institutional Review Board (IRB), which is
          concerned with the protection of subjects in a research project. You
          may reach the IRB office Monday-Friday by calling 312.467.2335 or
          writing: Institutional Review Board, The Chicago School of
          Professional Psychology, 325 N. Wells, Chicago, Illinois, 60654.{' '}
        </p>
        <p>
          <span className="bold">Consent to Participate in Research:</span>
        </p>
        <p>
          Clicking below indicates that I have read the description of the study
          and I agree to participate in the study.
        </p>
        <p>
          <span className="bold">Participant:</span>
        </p>
        <p>
          I have read the above information and have received satisfactory
          answers to my questions. I understand the research project, and the
          procedures involved have been explained. I agree to participate in
          this study. My participation is voluntary, and I do not have to click
          continue if I do not want to be part of this research project. I may
          print a copy of this consent form for my records.{' '}
        </p>

        <div>
          <label className="text-base font-medium text-black-900"></label>

          <fieldset className="mt-4">
            <legend className="sr-only">Notification method</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-5">
              {notificationMethods.map((notificationMethod) => (
                <div key={notificationMethod.id} className="flex items-center">
                  <input
                    id={notificationMethod.id}
                    name="notification-method"
                    type="radio"
                    defaultChecked={notificationMethod.id === 'email'}
                    className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                    onClick={consentHandler}
                  />
                  <label
                    htmlFor={notificationMethod.id}
                    className="constent__radioButtons ml-3 block text-lg font-medium text-gray-700"
                  >
                    {notificationMethod.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
      </div>

      <div className="buttonDiv">
        <button
          type="button"
          className="button inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => sessionHandler()}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Consent;
