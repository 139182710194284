import { useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

/**
 * Handles the wrapping of authentication related functionality
 * @param component
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export function PrivateRoute({ component: ChildComponent, ...rest }) {
  const auth = useAuth();
  const history = useHistory();

  // run an initial local auth check (usually during page refresh)
  useEffect(() => {
    async function localAuth() {
      auth.getCurrentUser().catch(() => history.push('/login'));
    }

    localAuth();
  }, []);

  const render = (props) => {
    if (auth.user) {
      return <ChildComponent {...props} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }
  };

  return <Route {...rest} render={render} />;
}
