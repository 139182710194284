import React from 'react';
import { useHistory } from 'react-router-dom';

import './page.css';
import './nudges.css';
import EmbedVideos from '../utilities/EmbedVideos';
import PUTNudge from '../utilities/PUTNudge';

const Nudges = () => {
  let nudge = Math.floor(Math.random() * 3);
  console.log(nudge);
  let history = useHistory();

  const sessionHandler = () => {
    if (nudge === 0) {
      nudge = 'HERO';
    }
    if (nudge === 1) {
      nudge = 'stress';
    }
    if (nudge === 2) {
      nudge = 'control';
    }

    PUTNudge(window.currentId, nudge);
    history.push('/pcq');
  };

  if (nudge === 0) {
    return (
      <div className="page App">
        <h1>Instructions:</h1>
        <h2>
          Please click the arrow below to start the video and watch it in its
          entirety. After watching, click the "Continue" button.
        </h2>
        <EmbedVideos embedId="rokGy0huYEA" />
        <div className="buttonDiv">
          <button
            type="button"
            className="button inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            onClick={() => sessionHandler()}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }
  if (nudge === 1) {
    return (
      <div className="page App">
        <h1>Instructions:</h1>
        <h2>
          Please click the arrow below to start the video and watch it in its
          entirety. After watching, click the "Continue" button.
        </h2>
        <EmbedVideos embedId="x_Q6ulfrNlQ" />
        <div className="buttonDiv">
          <button
            type="button"
            className="button inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            onClick={() => sessionHandler()}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }
  if (nudge === 2) {
    return (
      <div className="page App">
        <h1>Instructions:</h1>
        <h2>
          Please click the arrow below to start the video and watch it in its
          entirety. After watching, click the "Continue" button.
        </h2>
        <EmbedVideos embedId="qcggRdy_xmU" />
        <div className="buttonDiv">
          <button
            type="button"
            className="button inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            onClick={() => sessionHandler()}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }
};

export default Nudges;
