import PUTRecentShift from '../utilities/PUTRecentShift';

const shiftRatings = [-99, -99, -99, -99, -99];
const questionnaire = 'recentShift';

const ShiftRatings = (question, rating) => {
  // convert ratings into values

  //  shiftRecency
  if (rating === '<24 hours') {
    rating = 1;
  }
  if (rating === '1-6 days') {
    rating = 2;
  }
  if (rating === '1 week') {
    rating = 3;
  }
  if (rating === '2 weeks') {
    rating = 4;
  }
  if (rating === '>2 weeks') {
    rating = 5;
  }

  // shiftStress
  if (rating === '1') {
    rating = 1;
  }
  if (rating === '2') {
    rating = 2;
  }
  if (rating === '3') {
    rating = 3;
  }
  if (rating === '4') {
    rating = 4;
  }
  if (rating === '5') {
    rating = 5;
  }
  if (rating === '6') {
    rating = 6;
  }
  if (rating === '7') {
    rating = 7;
  }
  if (rating === '8') {
    rating = 8;
  }
  if (rating === '9') {
    rating = 9;
  }
  if (rating === '10') {
    rating = 10;
  }
  // shiftTime
  if (rating === 'day') {
    rating = 1;
  }
  if (rating === 'night') {
    rating = 2;
  }
  // shiftDuration
  if (rating === '<8 hours') {
    rating = 1;
  }
  if (rating === '8 hours') {
    rating = 2;
  }
  if (rating === '10 hours') {
    rating = 3;
  }
  if (rating === '12 hours') {
    rating = 4;
  }
  if (rating === '>12 hours') {
    rating = 5;
  }
  // shiftDay
  if (rating === 'weekday') {
    rating = 1;
  }
  if (rating === 'weekend') {
    rating = 2;
  }

  // place ratings in the array
  if (question === 'shiftRecency') {
    shiftRatings[0] = rating;
  }

  if (question === 'shiftStress') {
    shiftRatings[1] = rating;
  }

  if (question === 'shiftTime') {
    shiftRatings[2] = rating;
  }

  if (question === 'shiftDuration') {
    shiftRatings[3] = rating;
  }

  if (question === 'shiftDay') {
    shiftRatings[4] = rating;
  }

  console.log(questionnaire, shiftRatings);
  PUTRecentShift(shiftRatings);
};

export default ShiftRatings;
