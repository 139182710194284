import PUTPCQ from './PUTPCQ';

const pcqRatings = [-99, -99, -99, -99, -99, -99, -99, -99, -99, -99, -99, -99];
const questionnaire = 'PCQ';

const PCQRatings = (id, question, rating) => {
  console.log(id);
  if (rating === question + 'strongly-disagree') {
    rating = 1;
  }
  if (rating === question + 'disagree') {
    rating = 2;
  }
  if (rating === question + 'somewhat-disagree') {
    rating = 3;
  }
  if (rating === question + 'somewhat-agree') {
    rating = 4;
  }
  if (rating === question + 'agree') {
    rating = 5;
  }
  if (rating === question + 'strongly-agree') {
    rating = 6;
  }

  if (question === 'PCQ1') {
    pcqRatings[0] = rating;
  }

  if (question === 'PCQ2') {
    pcqRatings[1] = rating;
  }

  if (question === 'PCQ3') {
    pcqRatings[2] = rating;
  }

  if (question === 'PCQ4') {
    pcqRatings[3] = rating;
  }

  if (question === 'PCQ5') {
    pcqRatings[4] = rating;
  }

  if (question === 'PCQ6') {
    pcqRatings[5] = rating;
  }

  if (question === 'PCQ7') {
    pcqRatings[6] = rating;
  }
  if (question === 'PCQ8') {
    pcqRatings[7] = rating;
  }

  if (question === 'PCQ9') {
    pcqRatings[8] = rating;
  }

  if (question === 'PCQ10') {
    pcqRatings[9] = rating;
  }

  if (question === 'PCQ11') {
    pcqRatings[10] = rating;
  }

  if (question === 'PCQ12') {
    pcqRatings[11] = rating;
  }

  if (question === 'PCQ13') {
    pcqRatings[12] = rating;
  }

  if (question === 'PCQ14') {
    pcqRatings[13] = rating;
  }
  if (question === 'PCQ15') {
    pcqRatings[14] = rating;
  }

  if (question === 'PCQ16') {
    pcqRatings[15] = rating;
  }

  if (question === 'PCQ17') {
    pcqRatings[16] = rating;
  }

  if (question === 'PCQ18') {
    pcqRatings[17] = rating;
  }

  if (question === 'PCQ19') {
    pcqRatings[18] = rating;
  }

  if (question === 'PCQ20') {
    pcqRatings[19] = rating;
  }

  if (question === 'PCQ21') {
    pcqRatings[20] = rating;
  }
  if (question === 'PCQ22') {
    pcqRatings[21] = rating;
  }

  if (question === 'PCQ23') {
    pcqRatings[22] = rating;
  }

  if (question === 'PCQ24') {
    pcqRatings[23] = rating;
  }
  console.log(id, questionnaire, pcqRatings);
  PUTPCQ(id, pcqRatings);
};
export default PCQRatings;
