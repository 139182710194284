const PUTEmbed = (embedRatings) => {
  var xhr = new XMLHttpRequest();
  xhr.open(
    'PUT',
    'https://6jk00r88v6.execute-api.us-east-1.amazonaws.com/dev/embed'
  );
  xhr.onreadystatechange = function (event) {
    console.log(event.target.response);
  };
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(
    JSON.stringify({
      id: window.currentId,
      embed: embedRatings,
    })
  );
};

export default PUTEmbed;
