import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

import './page.css';
import PCQRatings from '../utilities/PCQRatings';
import ProgressBar from '../components/Progress';
import '../components/progress.css';

const pcqResponses = [
  { id: 'strongly-disagree', title: 'Strongly Disagree' },
  { id: 'disagree', title: 'Disagree' },
  { id: 'somewhat-disagree', title: 'Somewhat Disagree' },
  { id: 'somewhat-agree', title: 'Somewhat Agree' },
  { id: 'agree', title: 'Agree' },
  { id: 'strongly-agree', title: 'Strongly Agree' },
];

const PCQ = () => {
  useEffect(() => {
    const consent = 'yes';
    var currentTime = new Date();
    var date =
      currentTime.getFullYear() +
      '-' +
      (currentTime.getMonth() + 1) +
      '-' +
      currentTime.getDate();
    var time =
      currentTime.getHours() +
      ':' +
      currentTime.getMinutes() +
      ':' +
      currentTime.getSeconds();
    var startTime = date + ' ' + time;

    const lettersArray = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];

    const index = Math.floor(Math.random() * 26);
    const randomLetter = lettersArray[index];
    const randomNumber = Math.floor(Math.random() * 1000000);
    const id = randomLetter + randomNumber;

    window.currentId = id;

    console.log(consent);
    console.log(window.currentId);
    console.log(startTime);

    var xhr = new XMLHttpRequest();
    xhr.open(
      'POST',
      'https://6jk00r88v6.execute-api.us-east-1.amazonaws.com/dev/data-base'
    );
    xhr.onreadystatechange = function (event) {
      console.log(event.target.response);
    };
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(
      JSON.stringify({
        consent: consent,
        id: window.currentId,
        startTime: startTime,
      })
    );
  }, []);

  const [progressValue, setProgressValue] = useState(20);

  const [PCQ1, setPCQ1] = useState(false);
  const [PCQ2, setPCQ2] = useState(false);
  const [PCQ3, setPCQ3] = useState(false);
  const [PCQ4, setPCQ4] = useState(false);
  const [PCQ5, setPCQ5] = useState(false);
  const [PCQ6, setPCQ6] = useState(false);
  const [PCQ7, setPCQ7] = useState(false);
  const [PCQ8, setPCQ8] = useState(false);
  const [PCQ9, setPCQ9] = useState(false);
  const [PCQ10, setPCQ10] = useState(false);
  const [PCQ11, setPCQ11] = useState(false);
  const [PCQ12, setPCQ12] = useState(false);

  const [submitSkip, setSubmitSkip] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();

  const ratingHandler = (event) => {
    const rating = event.target.id;
    const question = event.target.name;

    if (question === 'PCQ1') {
      setPCQ1(question);
    }
    if (question === 'PCQ2') {
      setPCQ2(question);
    }
    if (question === 'PCQ3') {
      setPCQ3(question);
    }
    if (question === 'PCQ4') {
      setPCQ4(question);
    }
    if (question === 'PCQ5') {
      setPCQ5(question);
    }
    if (question === 'PCQ6') {
      setPCQ6(question);
    }
    if (question === 'PCQ7') {
      setPCQ7(question);
    }
    if (question === 'PCQ8') {
      setPCQ8(question);
    }
    if (question === 'PCQ9') {
      setPCQ9(question);
    }
    if (question === 'PCQ10') {
      setPCQ10(question);
    }
    if (question === 'PCQ11') {
      setPCQ11(question);
    }
    if (question === 'PCQ12') {
      setPCQ12(question);
    }
    setProgressValue(progressValue + 1);
    PCQRatings(window.currentId, question, rating);
  };

  const sessionHandler = () => {
    const skippedItems = [];
    if (PCQ1 === false) {
      skippedItems.push(' 1');
    }
    if (PCQ2 === false) {
      skippedItems.push(' 2');
    }
    if (PCQ3 === false) {
      skippedItems.push(' 3');
    }
    if (PCQ4 === false) {
      skippedItems.push(' 4');
    }
    if (PCQ5 === false) {
      skippedItems.push(' 5');
    }
    if (PCQ6 === false) {
      skippedItems.push(' 6');
    }
    if (PCQ7 === false) {
      skippedItems.push(' 7');
    }
    if (PCQ8 === false) {
      skippedItems.push(' 8');
    }
    if (PCQ9 === false) {
      skippedItems.push(' 9');
    }
    if (PCQ10 === false) {
      skippedItems.push(' 10');
    }
    if (PCQ11 === false) {
      skippedItems.push(' 11');
    }
    if (PCQ12 === false) {
      skippedItems.push(' 12');
    }

    console.log(skippedItems);

    if (PCQ1 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (PCQ2 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (PCQ3 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (PCQ4 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (PCQ5 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (PCQ6 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (PCQ7 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (PCQ8 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (PCQ9 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (PCQ10 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (PCQ11 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else if (PCQ12 === false && submitSkip === false) {
      setSubmitSkip(true);
      alert(
        `You have skipped the following item(s): [${skippedItems}]. Either: (1) Click 'OK' to complete the item(s) you skipped or (2) click "OK" and submit without completing the skipped item(s).`
      );
    } else {
      history.push('/embeddedness');
    }
  };

  return (
    <div>
      <ScrollToTop smooth />
      <div className="page">
        <h1>Psychological Capital*</h1>
        <p>Instructions:</p>
        <p>
          Below are statements that describe how you may think about yourself
          right now. Use the following scale to indicate your level of agreement
          or disagreement with each statement: strongly disagree - disagree -
          somewhat disagree - somewhat agree - agree - strongly agree
        </p>
        <hr />
        <p>
          1. I feel confident in representing my work area in meetings with
          management.
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {pcqResponses.map((PCQ1response) => (
              <div key={'PCQ1' + PCQ1response.id} className="flex items-center">
                <input
                  id={'PCQ1' + PCQ1response.id}
                  name="PCQ1"
                  type="radio"
                  // defaultChecked={PCQ1response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'PCQ1' + PCQ1response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {PCQ1response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          2. I feel confident contributing to discussions about the
          organization’s strategy.
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {pcqResponses.map((PCQ2response) => (
              <div key={'PCQ2' + PCQ2response.id} className="flex items-center">
                <input
                  id={'PCQ2' + PCQ2response.id}
                  name="PCQ2"
                  type="radio"
                  // defaultChecked={PCQ2response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'PCQ2' + PCQ2response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {PCQ2response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          3. I feel confident presenting information to a group of colleagues.
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {pcqResponses.map((PCQ3response) => (
              <div key={'PCQ3' + PCQ3response.id} className="flex items-center">
                <input
                  id={'PCQ3' + PCQ3response.id}
                  name="PCQ3"
                  type="radio"
                  // defaultChecked={PCQ3response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'PCQ3' + PCQ3response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {PCQ3response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          4. If I should find myself in a jam at work, I could think of many
          ways to get out of it.
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {pcqResponses.map((PCQ4response) => (
              <div key={'PCQ4' + PCQ4response.id} className="flex items-center">
                <input
                  id={'PCQ4' + PCQ4response.id}
                  name="PCQ4"
                  type="radio"
                  // defaultChecked={PCQ4response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'PCQ4' + PCQ4response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {PCQ4response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>5: Right now I see myself as being pretty successful at work.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {pcqResponses.map((PCQ5response) => (
              <div key={'PCQ5' + PCQ5response.id} className="flex items-center">
                <input
                  id={'PCQ5' + PCQ5response.id}
                  name="PCQ5"
                  type="radio"
                  // defaultChecked={PCQ5response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'PCQ5' + PCQ5response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {PCQ5response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p> 6. I can think of many ways to reach my current work goals.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {pcqResponses.map((PCQ6response) => (
              <div key={'PCQ6' + PCQ6response.id} className="flex items-center">
                <input
                  id={'PCQ6' + PCQ6response.id}
                  name="PCQ6"
                  type="radio"
                  // defaultChecked={PCQ6response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'PCQ6' + PCQ6response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {PCQ6response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          7. At this time, I am meeting the work goals that I have set for
          myself.
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {pcqResponses.map((PCQ7response) => (
              <div key={'PCQ7' + PCQ7response.id} className="flex items-center">
                <input
                  id={'PCQ7' + PCQ7response.id}
                  name="PCQ7"
                  type="radio"
                  // defaultChecked={PCQ7response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'PCQ7' + PCQ7response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {PCQ7response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>8. I can be “on my own,” so to speak, at work if I have to.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {pcqResponses.map((PCQ8response) => (
              <div key={'PCQ8' + PCQ8response.id} className="flex items-center">
                <input
                  id={'PCQ8' + PCQ8response.id}
                  name="PCQ8"
                  type="radio"
                  // defaultChecked={PCQ8response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'PCQ8' + PCQ8response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {PCQ8response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>9. I usually take stressful things at work in stride.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {pcqResponses.map((PCQ9response) => (
              <div key={'PCQ9' + PCQ9response.id} className="flex items-center">
                <input
                  id={'PCQ9' + PCQ9response.id}
                  name="PCQ9"
                  type="radio"
                  // defaultChecked={PCQ9response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'PCQ9' + PCQ9response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {PCQ9response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          10. I can get through difficult times at work because I’ve experienced
          difficulty before.
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {pcqResponses.map((PCQ10response) => (
              <div
                key={'PCQ10' + PCQ10response.id}
                className="flex items-center"
              >
                <input
                  id={'PCQ10' + PCQ10response.id}
                  name="PCQ10"
                  type="radio"
                  // defaultChecked={PCQ10response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'PCQ10' + PCQ10response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {PCQ10response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>11. I always look on the bright side of things regarding my job.</p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {pcqResponses.map((PCQ11response) => (
              <div
                key={'PCQ11' + PCQ11response.id}
                className="flex items-center"
              >
                <input
                  id={'PCQ11' + PCQ11response.id}
                  name="PCQ11"
                  type="radio"
                  // defaultChecked={PCQ11response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'PCQ11' + PCQ11response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {PCQ11response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p>
          12. I’m optimistic about what will happen to me in the future as it
          pertains to work.
        </p>
        <fieldset className="radialButtons mt-0">
          <legend className="sr-only">Notification method</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-2">
            {pcqResponses.map((PCQ12response) => (
              <div
                key={'PCQ12' + PCQ12response.id}
                className="flex items-center"
              >
                <input
                  id={'PCQ12' + PCQ12response.id}
                  name="PCQ12"
                  type="radio"
                  // defaultChecked={PCQ12response.id === 'email'}
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                  onClick={ratingHandler}
                />
                <label
                  htmlFor={'PCQ12' + PCQ12response.id}
                  className="ml-1 block text-sm font-medium text-gray-700"
                >
                  {PCQ12response.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <hr />
        <p className="citation">
          *Copyright © 2007 by Fred Luthans, Bruce J. Avolio & James B. Avey.
          All rights reserved in all media.
        </p>
      </div>
      <div className="buttonDiv">
        <button
          type="button"
          className="button inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => sessionHandler()}
        >
          Submit
        </button>
      </div>
      <p className="page__count">Page 1 of 5</p>
      <div className="progressBar">
        <ProgressBar
          color={'green'}
          width={'300px'}
          value={progressValue}
          max={100}
        />
      </div>
    </div>
  );
};

export default PCQ;
