import { useState, useContext, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Redirect, useHistory } from 'react-router-dom';
import { CurrentUserName } from '../App';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const LoginButton = (props) => {
  if (props.loading) {
    return (
      <button
        type="button"
        onClick={props.handleLogin}
        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      >
        <div className=" flex justify-center items-center">
          <div className="animate-spin rounded-full h-6 w-6 border-t-4 border-b-4 border-green-500" />
        </div>
      </button>
    );
  }

  return (
    <button
      type="button"
      onClick={props.handleLogin}
      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
    >
      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
        <svg
          className="h-5 w-5 text-green-500 group-hover:text-green-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clipRule="evenodd"
          />
        </svg>
      </span>
      Log in
    </button>
  );
};

/**
 * Displays login page
 * @returns {JSX.Element}
 * @constructor
 */
const Login = () => {
  const auth = useAuth();
  let history = useHistory({ forceRefresh: true });
  const [username, setUsername] = useState('');
  console.log(username);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [shouldSetNewPassword, setShouldSetNewPassword] = useState();

  const [currentUserName, setCurrentUserName] = useContext(CurrentUserName);
  setCurrentUserName(username);

  useEffect(() => {
    console.log(currentUserName);
  }, []);

  /**
   * Click handler for login button
   */
  const handleLogin = async () => {
    try {
      setLoading(true);

      if (shouldSetNewPassword) {
        const session = await auth.newPasswordChange({
          username: username,
          password: password,
          newPassword: newPassword,
        });

        // console.log(session);
      }

      const loginResult = await auth.login(username, password);

      if (loginResult.FORCE_PASSWORD_CHANGE) {
        return setShouldSetNewPassword(true);
      }

      setLoading(false);
      history.push('/');
    } catch (e) {
      setLoading(false);
      if (!shouldSetNewPassword) {
        alert(
          'Incorrect username or password. Please enter the username and password provided in the email you received from the study coordinator.'
        );
      }
      // console.log(e);
    }
  };

  /**
   * Handles rendering of form fields, whether regular login or first time login (password change)
   * @returns {JSX.Element}
   */
  const renderFormFields = () => {
    if (shouldSetNewPassword) {
      return (
        <div className="rounded-md shadow-sm -space-y-px">
          <p>
            Since this is your first time logging in, please set a new password.
          </p>
          <div>
            <label htmlFor="new-password" className="sr-only">
              New password
            </label>
            <input
              id="new-password"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="New password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </div>

          {/*TODO implement this*/}
          {/*<div className="flex items-center justify-between">*/}
          {/*  <div className="text-sm">*/}
          {/*    <a href="#" className="font-medium text-green-600 hover:text-green-500">*/}
          {/*      Forgot your password?*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      );
    }
  };

  if (auth.user) {
    return <Redirect to={'/'} />;
  }

  return (
    <div className="flex items-center lg:min-h-full lg:pb-16 sm:min-h-screen justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="items-center">
          {/* <img className="h-24 mt-44 mx-auto w-auto" src={logo} alt="Logo" /> */}
          <h2 className="mt-4 text-center text-2xl font-bold text-gray-600"></h2>
          <h2 className="mt-2 text-center text-lg text-gray-600">
            Log in to the Survey
          </h2>
        </div>
        <form className="mt-4 pb-16 space-y-6" action="#" method="POST">
          {renderFormFields()}
          <div>
            <LoginButton handleLogin={handleLogin} loading={loading} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
