import { useHistory } from 'react-router-dom';

import './page.css';

const Help = () => {
  let history = useHistory();

  const sessionHandler = () => {
    history.push('/welcome');
  };

  return (
    <div className="page">
      <h1>Help</h1>
      <p className="instructions">
        If you have questions or concerns regarding the study, contact the
        prinicipal invesitigator, Eric Parmenter, at
        eparmenter.ego.thechicagoschool.edu. For issues with logging in or with
        the website, email support@dropBysolutions.com.
      </p>
      <div className="buttonDiv">
        <button
          type="button"
          className="button inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => sessionHandler()}
        >
          Return
        </button>
      </div>
    </div>
  );
};

export default Help;
