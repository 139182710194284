import PUTEmbed from '../utilities/PUTEmbed';

const embedRatings = [-99, -99, -99, -99, -99, -99, -99];
const questionnaire = 'embed';

const EmbedRatings = (question, rating) => {
  if (rating === question + 'strongly-disagree') {
    rating = 1;
  }
  if (rating === question + 'disagree') {
    rating = 2;
  }
  if (rating === question + 'somewhat-disagree') {
    rating = 3;
  }
  if (rating === question + 'somewhat-agree') {
    rating = 4;
  }
  if (rating === question + 'agree') {
    rating = 5;
  }
  if (rating === question + 'strongly-agree') {
    rating = 6;
  }

  if (question === 'embed1') {
    embedRatings[0] = rating;
  }

  if (question === 'embed2') {
    embedRatings[1] = rating;
  }

  if (question === 'embed3') {
    embedRatings[2] = rating;
  }

  if (question === 'embed4') {
    embedRatings[3] = rating;
  }

  if (question === 'embed5') {
    embedRatings[4] = rating;
    console.log(rating);
  }

  if (question === 'embed6') {
    if (rating === 1) {
      rating = 6;
    } else if (rating === 2) {
      rating = 5;
    } else if (rating === 3) {
      rating = 4;
    } else if (rating === 4) {
      rating = 3;
    } else if (rating === 5) {
      rating = 2;
    } else if (rating === 6) {
      rating = 1;
    }

    embedRatings[5] = rating;
    console.log(rating);
  }

  if (question === 'embed7') {
    embedRatings[6] = rating;
  }

  console.log(questionnaire, embedRatings);
  PUTEmbed(embedRatings);
};

export default EmbedRatings;
