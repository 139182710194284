import { useState, createContext } from 'react';
import Navigation from './components/Navigation';
import Login from './pages/Login';
import { Route } from 'react-router';
import { ProvideAuth } from './hooks/useAuth';
import Footer from './components/Footer';

export const CurrentUserName = createContext();

export default function App() {
  const [currentUserName, setCurrentUserName] = useState('');
  return (
    <CurrentUserName.Provider value={[currentUserName, setCurrentUserName]}>
      <ProvideAuth>
        <Navigation />

        <Route path={'/login'}>
          <Login />
        </Route>

        <Footer />
      </ProvideAuth>
    </CurrentUserName.Provider>
  );
}

// import React from 'react';
// import './App.css';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// import Page1 from './pages/Page1';
// import Page2 from './pages/Page2';

// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <Switch>
//           <Route path="/" exact component={Page1} />
//           <Route path="/page2" component={Page2} />
//         </Switch>
//       </div>
//     </Router>
//   );
// }

// export default App;
