import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';

const Container = Styled.div`
    progress {
        margin-right: 8px;
    }

    progress[value] {
        width: ${(props) => props.width};

        -webkit-appearance: none;
        appearance: none;
    }

    progress[value]::-webkit-progress-bar {
      height: 15px;
      border-radius: 20px;
      background-color: gray;
    }

    progress[value]::-webkit-progress-value {
      height: 15px;
      border-radius: 20px;
      background-color: ${(props) => props.color};
    }
`;

const ProgressBar = ({ value, max, color, width }) => {
  return (
    <Container color={color} width={width}>
      <progress value={value} max={max} />
      <span>{Math.trunc((value / max) * 100)}%</span>
    </Container>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.string,
};

ProgressBar.defaultProps = {
  max: 100,
  color: 'green',
  width: '300px',
};

export default ProgressBar;
